import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

const body = $('body');
let winH = $(window).height();
let winW = $(window).width();

let tar_offset = document.querySelector('#header_nav').offsetHeight*2 + 20;

function nav_show(){
	body.addClass('navopen');
	body.removeClass('navclose');
}
function nav_hide(){
	body.removeClass('navopen');
	body.addClass('navclose');
}
function navInit(){
	body.addClass('navclose');
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
	// window.addEventListener('scroll', function(){
	// 	nav_hide();
	// });
}

function navChange(){
	nav_hide();
	const namespace = $('[data-barba="container"]').attr('data-barba-namespace');
	$('#nav').attr('data-namespace', namespace);
	$('#nav').find('a').each(function(){
		$(this).removeClass('current');
		const _a_name = $(this).attr('data-namespace');
		if( _a_name == namespace ){
			$(this).addClass('current');
		}
	});
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.set(_elmc,{ opacity:0, y:60 });
		gsap.to(_elmc,
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1),
				},
				onStart: function(){
					_tar.classList.add('elon');
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
	body.addClass('scrltop');
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


function anchorLink(){
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		// e.stopPropagation();
		nav_hide();
		let hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		let currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}

const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}

function expandFunc() {
	const expand = (parent, state) => {
		if(state){
			parent.classList.add('open');
		}else{
			parent.classList.remove('open');
		}
		const exc = parent.querySelector('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: (!state) ? 0 : "auto",
				duration:0.3,
				ease: "power2.inOut",
				onComplete: () => {
					ScrollTrigger.refresh();
				}
			});
		});
	}
	const expand_event = (e) => {
		let parent = e.target.closest('.ex');
		const result = parent.classList.contains('open');
		if (result) {
			expand(parent, false);
		}else{
			expand(parent, true);
		}
	};
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


function top_kv_swiper(){
	const swiper = new Swiper('#topkvSwiper', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 600,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
		loop: true,
	});
}


let logo_tl_arr=[];
function logo_anime_func(){
	const random = (min, max) => Math.random() * (max - min) + min;
	gsap.utils.toArray('#logo_anime path').forEach(path => {
		const logo_tl = gsap.timeline({
			repeat:-1,
			repeatRefresh:true,
			repeatDelay:0.8,
		});
		logo_tl.to(path, {
			scaleX:()=>{return random(0.6,1.1)},
			scaleY:()=>{return random(0.6,1.1)},
			skewX:()=>{return random(-45,45)},
			skewY:()=>{return random(-45,45)},
			rotation:()=>{return random(-180,180)},
			transformOrigin:'50% 50%',
			duration: 0.8,
			ease:'power4.inOut',
		})

		logo_tl_arr.push(logo_tl);
	});
}
function logo_anime_stop(){
	if(logo_tl_arr.length > 0){
		logo_tl_arr.forEach(logo_tl => {
			logo_tl.pause();
		});
	}
}

function top_feature_swiper(){
	const thumb_swiper = new Swiper('#topfeatureSwiper_text', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 800,
		loop: true,
	});
	const swiper = new Swiper('#topfeatureSwiper', {
		slidesPerView: 1.0001,
		spaceBetween: 0,
		speed: 800,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				return '<span class="' + className + '">' + (index + 1) + "</span>";
			},
		},
		thumbs: {
			swiper: thumb_swiper,
		},
	});
}

function single_swiper(){
	gsap.utils.toArray('.single_swiper').forEach(el => {
		const parent = el.parentElement;
		const swiper = new Swiper(el, {
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 600,
			loop: true,
			navigation: {
				nextEl: parent.querySelector('.snext'),
				prevEl: parent.querySelector('.sprev'),
			},
			pagination: {
				el: parent.querySelector('.sw_pagination'),
				type:'custom',
				renderCustom: function (swiper, current, total) {
					return current + ' / ' + total;
				}
			},
		});
	});
}


function visa_modal(){
	const modal = document.getElementById('modal_visa');
	const close = document.getElementById('modal_close');
	gsap.utils.toArray('.visabtn').forEach(b => {
		b.addEventListener('click', function(e){
			e.preventDefault();
			let href = this.hash;
			gsap.to('#modal_wrap', { duration:0.6, scrollTo:{y:href}, ease:'power2'});	
			modal.classList.add('open');
		});
	});
	close.addEventListener('click', function(){
		modal.classList.remove('open');
	});
}

function check_externalLink(){
	const links = document.querySelectorAll('a');
	links.forEach(link => {
		let href = link.getAttribute('href');
		if (href && href.startsWith('http') && !href.startsWith(location.origin)) {
			link.setAttribute('target', '_blank');
		}
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		check_externalLink();
		anchorLink();
		navChange();
		elFunc();
		expandFunc();
		logo_anime_stop();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		top_kv_swiper();
		logo_anime_func();
		top_feature_swiper();
	},
	article_singleFunc: function(){
		single_swiper();
		visa_modal();
	},
};

const replaceBogo = function(data){
	let target = '#wrapper';
	if(data){
		target = data.next.html;
	}
	const $newPageBogo = $(target).find('.bogo-language-switcher').html();
	const bogo = document.getElementById('bogo');
	bogo.innerHTML = $newPageBogo;
	bogo.querySelectorAll('a').forEach(a => {
		a.classList.add('nopj');
	});
}

function pjaxSettings(){

	barba.use(barbaPrefetch);

	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if (href == null) {
			href = el.getAttribute('xlink:href');
		}
		if (el.classList.contains('pj')) {
			return false;
		}
		if (el.classList.contains('nopj')) {
				return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
				el.setAttribute('target', '_blank');
				return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/, "");
		if (href.startsWith(location.protocol + '//' + location.host)) {
				if (href.indexOf('#') > -1 && extract_hash == url) {
						return false;
				}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
				el.setAttribute('target', '_blank');
				return true;
		}
		return false;
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					resolve();
				}});
			}
		})
	}


	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('#footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl nav_bk');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
		scroll(data);
		async function callerFun(){
			pageCheck();
			if( document.getElementById('form_blc') ){
				contact_Cf7();
			}	
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onStart:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

async function pageCheck() {
	await new Promise((resolve, reject) => {
		PageEvents.commonFunc();

		if( document.getElementById('top') ){
			PageEvents.topFunc();
		}else{
			body.addClass('inpage');
		}
		if( document.getElementById('articles') ){
			PageEvents.article_singleFunc();
		}
		body.addClass('ready');
		resolve();
	})
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
	window.addEventListener('load', function(){
		body.addClass('siteloaded');
	});
}

initialize();

})();
})( jQuery );

}